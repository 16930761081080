import React, { useContext, useEffect, useMemo, useState } from 'react';
import useAccountRegistry from './account-registry';
import useAccountRegistryByIdentifierContext from './account-registry-by-identifier';
import UserAuthorizationContext from './authorization';
import RegistrationsContext from './registrations';
import useWevViewPortMobile from 'hooks/useWevViewPortMobile';
import useConstants from 'services/contants';

const ApplicationContext = React.createContext({
  loading: false,
  hasAccount: false,
  loaded: false,
});

export const ApplicationProvider = ({ children }) => {
  const {
    success: successAuth,
    error: errorAuth,
    currentAccountId,
  } = useContext(UserAuthorizationContext);
  const { success: successReg, error: errorReg } =
    useContext(RegistrationsContext);

  const { PAGE_STATES } = useConstants();

  const COMPONENT_STATES = React.useMemo(
    () => ({
      ...PAGE_STATES,
      IDLE: 'IDLE',
    }),
    [PAGE_STATES]
  );

  const {
    registry,
    success: successAccountIdentifer,
    error: errorAccountIdentifier,
  } = useAccountRegistryByIdentifierContext();

  const { success: successAccount, error: errorAccount } = useAccountRegistry();

  const [pageState, setPageState] = useState(COMPONENT_STATES.INITIAL);

  const loading = pageState === COMPONENT_STATES.LOADING;
  const idle = pageState === COMPONENT_STATES.IDLE;
  const initial = pageState === COMPONENT_STATES.INITIAL;

  useWevViewPortMobile();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setPageState(COMPONENT_STATES.LOADING);
    }
  }, [COMPONENT_STATES.LOADING]);

  useEffect(() => {
    let mounted = true;

    if (mounted && !initial && (successAuth || errorAuth)) {
      setPageState(COMPONENT_STATES.IDLE);
    }
    return () => (mounted = false);
  }, [
    initial,
    successReg,
    errorReg,
    successAuth,
    errorAuth,
    successAccount,
    successAccountIdentifer,
    errorAccount,
    errorAccountIdentifier,
    COMPONENT_STATES.IDLE,
  ]);

  const hasAccount = useMemo(() => currentAccountId, [currentAccountId]);

  const defaultValues = {
    loading,
    hasAccount,
    loaded: idle,
    registry: registry,
  };

  return (
    <ApplicationContext.Provider value={defaultValues}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => useContext(ApplicationContext);

export default ApplicationContext;
